// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);


// Theme Config

body {
    --primary-color: #ff6c36;
    --primary-lighter-color: #ffd3c3;
    --primary-darker-color: #ff4f22;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};


    letter-spacing: -0.015em;
    font-synthesis: none;
    // -webkit-font-smoothing: antialiased;
    font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
    -webkit-text-size-adjust: 100%;
}

$mat-primary: (
    main: #ff6c36,
    lighter: #ffd3c3,
    darker: #ff4f22,
    200: #ff6c36,
    // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
    --accent-color: #ff6c36;
    --accent-lighter-color: #ffd3c3;
    --accent-darker-color: #ff4f22;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
    main: #ff6c36,
    lighter: #ffd3c3,
    darker: #ff4f22,
    200: #ff6c36,
    // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);


$mat-white: (
    main: #f4f4f4,
    lighter: #fff,
    darker: #eee,
    200: #f4f4f4,
    // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);
$theme-white: mat.define-palette($mat-white, main, lighter, darker);

body {
    --white-color: #f4f4f4;
    --white-lighter-color: #fff;
    --white-darker-color: #eee;
    --text-white-color: #{$light-primary-text};
    --text-white-lighter-color: #{$dark-primary-text};
    --text-white-darker-color: #{$light-primary-text};
}

$theme: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    white: $theme-white,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
);
$altTheme: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    white: $theme-white,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Roboto';
    }
}

.mat-mdc-form-field-infix {
    min-height: 48px;
    max-height: 48px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: #fff;
    box-shadow: 5px 5px 10px #0000000d !important;
    border: 1px solid #e6e6e6;
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
// or
@import "bootstrap/scss/bootstrap";
// then import toastr scss
@import "ngx-toastr/toastr-bs4-alert";



* {
    font-family: 'Roboto';
}

.pac-container {
    z-index: 1054 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

body {
    background-color: white;
    font-family: 'Roboto';
}

html {
    scroll-behavior: auto;
}

.navbar li>a {
    color: black !important;
    font-size: 14px !important;
}

footer ul>li>a {
    color: #ddd;
}

.btn-facebook {
    background-color: #44659b !important;
    color: white !important;
    padding: 20px;
    font-size: 20px;
}

.btn-google {
    background-color: #de4935 !important;
    color: white !important;
    padding: 20px;
    font-size: 20px;
}

.download-app img {
    width: 100%;
    height: 35px;
}

.download-app div {
    margin-top: 20px;
    display: inline-block;
    width: 110px;
}

.footer-logos img {
    margin: 20px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
// input[type=number] {
//     appearance: textfield;
// }

.pagination {
    justify-content: center !important;
}

#instafeed img {
    display: block;
    width: 100%;
    max-height: 33vh;
    height: 33vh;
    object-fit: cover;
}

.nav-button {
    margin-left: 20px;
}

.shippn-nav {
    display: flex;
    align-items: center;
}

.footer-application-area {
    text-align: right;
}

.footer-google-play {
    width: 86%;
}

.footer-app-store {
    width: 86%;
}

.footer-shippn-blog {
    width: 80%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 2px !important;
}

#welcome {
    padding-top: 640px !important;
}

.header-underline-white {
    text-decoration: none;
    position: relative;
}

.header-underline-white::after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -16px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 3%;
    background: white;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.carousel {
    margin-bottom: 0;
    padding: 0 40px 30px 40px;
}

/* The controlsy */
.carousel-control {
    left: -33px;
    height: 50px;
    width: 50px;
    font-size: 32px;
    background: none !important;
    background-image: none !important;
    border: 2px solid white;
    border-radius: 50%;
    margin-top: 175px;
}

.carousel-control.right {
    right: -33px;
}

/* The indicators */
.carousel-indicators {
    right: 50%;
    top: auto;
    bottom: -10px;
    margin-right: -19px;
}

/* The colour of the indicators */
.carousel-indicators li {
    background: #cecece;
}

// .carousel-indicators .active {
//     background: #428bca;
// }

.btn-shippn-orange {
    color: black !important;
    border: 1px solid #ff6c36 !important;
    border-radius: 6px;
    padding: 6px 15px !important;
    transition: 0.5;

}

.btn-shippn-orange:hover {
    color: white !important;
    border: 1px solid #ff6c36 !important;
    background: #ff6c36 !important;
    border-radius: 6px;
    padding: 6px 15px !important;
    transition: 0.5;
}

.shippn-from-to-area {
    padding: 0px 25px 0px 25px;
    display: flex;
    align-items: center;
}

.shippn-from-to-area-go-btn {
    background-color: #ff6c36 !important;
    padding: 7px 12px !important;
    border: #ff6c36 !important;
    color: white !important;
}

.shippn-from-to-area-right-icon {
    display: block !important;
    font-size: 21px;
    margin-left: 10px;
    margin-right: 10px;
}

.shippn-from-to-area-under-icon {
    display: none !important;
    font-size: 21px;
    margin-left: 10px;
    margin-right: 10px;
}

.shippn-verified-container {
    width: 600px !important;
}

.rate-div {
    max-width: 50%;
    margin-left: 45px;
    margin-top: 33px;
    padding-right: 5px;
}

.getaddress-left-button {
    display: block;
}

.getaddress-right-button {
    display: none;
}

.shippn-top-host-area-name {
    float: left;
}

.shippn-top-host-area-country {
    float: right;
}

.shippn-top-host-area-rating {
    float: right;
    margin-top: 5px;
}

.top-host-carousel-control-left {
    border: none;
    font-size: 50px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.top-host-carousel-control-right {
    border: none;
    font-size: 50px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.blog-line-height {
    line-height: 19px;
}

.blog-creator-name {
    float: left;
}

.blog-read-more {
    float: right;
}

#instafeed {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.shippn-homepage-shop-anywhere {
    margin-top: 60px;
}

.shippn-homepage-main-subtitle {
    margin-top: 136px;
    font-size: 18px;
}

@media (max-width: 576px) {
    .shippn-homepage-main-subtitle {
        margin-top: 160px;
    }
}

.shippn-input {
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%) !important;
    padding: 20px 16px !important;
    background-color: transparent !important;
    border-color: #e6e6e6 !important;
    font-size: 13px !important;
    border-radius: 5px !important;
}

select,
.input-group-prepend {
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%) !important;
    border-color: #e6e6e6 !important;
}

.phone-input:focus,
.phone-input:focus:active {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.shippn-input:focus {
    background-color: white !important;
}

textarea {
    resize: none;
}

.shippn-input::placeholder {
    color: #000000c4;
}

.header-underline {
    text-decoration: none;
    position: relative;
}

.header-underline::after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -16px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 3%;
    background: black;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.header-underline-how-it-works {
    text-decoration: none;
    position: relative;
    color: #5795bb;
}

.header-underline-how-it-works::after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: -16px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 14%;
    background: #7c7c7c;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.homepage-tab-active {
    background-color: #3d85b0;
    border-bottom: 1px solid #3d85b0;
    color: white;
}

ol,
ul,
dl {
    font-size: 14px;
}

footer ul {
    padding: 0;
    list-style: none;
    font-size: 12px;
}

.accordion ol {
    list-style: decimal;
    padding-left: 15px;
    font-size: 14px;
}

li a {
    color: white;
    margin-top: 5px;
}

li {
    margin-top: 5px;
}

li a:hover {
    color: white;
    text-decoration: none;
}

.black-white-img {
    filter: grayscale(100%);
    transition: 1s;
}

.black-white-img:hover {
    filter: grayscale(0%);
    transition: 1s;
}

#input_container {
    position: relative;
    width: 100%;
}

#input_img {
    position: absolute;
    bottom: 11px;
    right: 5px;
    width: 24px;
    height: 22px;
    font-size: 20px;
    color: red;
}

.shippn-border-red {
    border: 1px solid red !important;
}

.btn-loading-white {
    background-image: url(./assets/img/loading-white.gif);
    background-repeat: no-repeat;
    background-position: center;
}

.btn-loading-black {
    background-image: url(./assets/img/loading-black.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18%;
}

#shippn-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ebebeb;
}

#shippn-scroll::-webkit-scrollbar {
    width: 6px;

}

#shippn-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff993e;
}

.host-list-selected-country {
    color: #222222;
    background-color: #909090;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-item.active .page-link {
    color: black !important;
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
}

.page-link {
    color: black !important;
    cursor: pointer !important;
}

dp-date-picker.dp-material .dp-picker-input {
    height: 42px !important;
    width: 100% !important;
    padding-left: 10px !important;
    border-radius: 5px !important;
    border: 1px solid #000000a6 !important;
    margin-top: 14px;
}

.selected-list .c-btn {
    min-height: 42px;
    border: 1px solid #e6e6e6 !important;
    background: white;
    margin-top: 15px;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%);
}

.selected-list .c-list .c-token .c-remove svg {
    fill: black !important;
}

.about-team-img:hover {

    filter: brightness(1);
    transition: 1s;
    cursor: pointer;
}

.vl {
    border-left: 6px solid green;
    height: 100%;
}

.days .div-class {
    font-size: 14px;
    margin: 0.3rem;
    width: calc(235px / 7);
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
}

.days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
}

//   .days .div-class:hover:not(.today) {
//     background-color: #262626;
//     border: 0.2rem solid #777;
//     cursor: pointer;
//   }

.today {
    background-color: #00a7ff;
    border-radius: 10%;
    color: white;
}

.prev-date,
.next-date {
    opacity: 0.5;
}

.modal-dialog-right {
    margin: 0 !important;
    height: 100% !important;
    margin-left: auto !important;
}

.bfmQuantityInput {
    width: 50px;
    text-align: center;
    height: 31.5pt;
    border-radius: 4pt;
    border: 1px solid #979797;
    text-align: center;
}

.bfmQuantityButtons {
    width: 20.5pt;
    height: 19.5pt;
    border-radius: 4pt;
    background-color: #212121;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 10pt;

}

div.item-link {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shippn-not-active-card {
    opacity: 0.7;
    transition: 1s;
    filter: grayscale(100%);
}

.form-control-sm {
    border: 1px solid black;
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 200px) {
    .bfm-slide-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .bfm-carousel-control-prev {
        left: -125px !important;
    }

    .bfm-carousel-control-next {
        right: -125px !important;
    }

    .country-slide-padding {
        padding-left: 50px;
        padding-right: 50px;
    }

    .mobile-text-center {
        text-align: center !important;
    }

    .shippn-pagination-float {
        float: none;
    }

    .dashboard-card-content-border-radius {
        border-radius: 0px 0px 10px 10px;
    }

    .dashboard-card-header-border-radius {
        border-radius: 10px 10px 0px 0px;
    }

    .dashboard-nav {
        padding: 0;
        list-style: none;
        margin-top: 10px;
        display: inline-flex;
        overflow: auto;
        width: 100%;
    }

    .dashboard-nav-height {
        height: fit-content;
        border-radius: 10px;
    }

    .dashboard-nav-padding-right {
        padding-right: 15px;
    }

    .dashboard-card-padding-left {
        padding-left: 15px;
    }

    .dashboard-nav li {
        min-width: fit-content;
        display: inline-flex;
        align-items: center;
        padding: 7px 25px;
        cursor: pointer;
        color: white;
        border-radius: 10px;
        margin-top: 0px;
    }

    .delete-account-left-to-center {
        text-align: center;
    }

    .bfm-explanation-left-to-center {
        text-align: center;
    }

    .bfm-explanation-right-to-center {
        text-align: center;
    }

    .delete-account-right-to-center {
        text-align: center;
    }

    .address-right-to-center {
        text-align: center;
    }

    .address-left-to-center {
        text-align: center;
    }

    .shippn-become-a-host-background-color {
        background: linear-gradient(180deg, #f4f4f4 25%, #414141 25%)
    }

    .become-a-host-second-area-border {
        border-bottom: 1px solid #00000029;

    }

    .about-team-img {
        width: 130px;
        border-radius: 50%;
        filter: brightness(0.8);
        transition: 1s;
    }

    .prohibited-area-padding {
        padding: 20px 50px;
    }

    .host-detail-review-profile-area {
        margin-left: 0;
    }

    .host-detail-review-rating-area {
        margin-top: 0;
        margin-right: 0;
    }


    .shippn-pricing-width {
        width: 100%;
    }

    .host-list-order-properties-padding {
        padding: 0 16px;
    }

    .shippn-padding-none {
        padding-left: 15px;
        padding-right: 15px;
    }

    .terms-text {
        padding: 60px 35px;
    }

    .mobile-text-center-desktop-left {
        text-align: center;
        margin-top: 20px;
    }

    .mobile-text-center-desktop-right {
        text-align: center;
        margin-top: 20px;
    }

    .mobile-text-center-desktop-center {
        text-align: center;
    }

    #instafeed img {
        display: block;
        width: 100%;
        max-height: 23vh;
        height: 23vh;
        object-fit: cover;
    }

    .top-countries-country-name {
        font-size: 14px;
    }

    #instafeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .btn-shippn-orange {
        color: black !important;
        border: 1px solid #ff6c36 !important;
        border-radius: 6px;
        padding: 6px 15px !important;
        transition: 0.5s !important;
        width: 27%;
        margin-bottom: 15px;

    }

    .btn-shippn-orange:hover {
        color: white !important;
        border: 1px solid #ff6c36 !important;
        background: #ff6c36 !important;
        border-radius: 6px;
        padding: 6px 15px !important;
        transition: 0.5s !important;
    }

    .nav-button {
        margin-left: 0px;
    }

    .carousel-control-prev {
        left: -12px;
        width: 45px;
    }

    .carousel-control-next {
        right: -12px;
        width: 45px;
    }

    .mobile-center {
        text-align: center;
    }

    .header-underline-white-left {
        text-decoration: none;
        position: relative;
    }

    .header-underline-white-left::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 9%;
        background: white;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .header-underline-black-left {
        text-decoration: none;
        position: relative;
    }

    .header-underline-black-left::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        left: 0;
        right: 0;
        width: 9%;
        background: black;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .header-underline-black-right {
        text-decoration: none;
        position: relative;
    }

    .header-underline-black-right::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 9%;
        background: black;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .footer-application-area {
        text-align: center;
    }

    .footer-google-play {
        width: 33%;
    }

    .footer-app-store {
        width: 33%;
    }


    .footer-shippn-blog {
        width: 30%;
    }

    .footer-logo {
        width: 45%;
        margin-top: 7px;
        margin-bottom: 25px;
    }
}

@media (min-width: 578px) {

    .country-slide-padding {
        padding-left: 80px;
        padding-right: 80px;
    }

    .shippn-become-a-host-background-color {
        background: linear-gradient(180deg, #f4f4f4 42%, #414141 42%)
    }

    #instafeed img {
        display: block;
        width: 100%;
        max-height: 31vh;
        height: 31vh;
        object-fit: cover;
    }

    .mobile-text-center-desktop-center {
        text-align: center;
    }

    #instafeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .carousel-control-prev {
        left: -50px;
    }

    .carousel-control-next {
        right: -50px;
    }

    .top-countries-country-name {
        font-size: 25px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .country-slide-padding {
        padding-left: 70px;
        padding-right: 70px;
    }

    .address-left-to-center {
        text-align: left;
    }

    .address-right-to-center {
        text-align: right;
    }

    .bfm-explanation-left-to-center {
        text-align: left;
    }

    .bfm-explanation-right-to-center {
        text-align: right;
    }

    .delete-account-left-to-center {
        text-align: left;
    }

    .delete-account-right-to-center {
        text-align: right;
    }

    .shippn-become-a-host-background-color {
        background: linear-gradient(180deg, #f4f4f4 34%, #414141 34%)
    }

    .about-team-img {
        width: 120px;
        border-radius: 50%;
        filter: brightness(0.8);
        transition: 1s;
    }

    .prohibited-area-padding {
        padding: 20px 200px;
    }

    .host-detail-review-profile-area {
        margin-left: 15px;
    }

    .host-detail-review-rating-area {
        margin-top: 15px;
        margin-right: 15px;
    }

    .host-list-order-properties-padding {
        padding: 0 27px;
    }

    .terms-text {
        padding: 60px 90px;
    }

    .shippn-pricing-width {
        width: 80%;
    }

    .mobile-text-center-desktop-left {
        text-align: left;
        margin-top: 0px;
    }

    .mobile-text-center-desktop-right {
        text-align: right;
        margin-top: 0px;
    }

    .shippn-padding-none {
        padding-left: 0px;
        padding-right: 0px;
    }

    .signup-background-woman {
        padding-top: 30px;
        background: url('/assets/img/signup-background-woman.webp');
        background-size: 44%;
        background-repeat: no-repeat;
        background-position: left bottom;
    }

    .signin-background-woman {
        padding-top: 85px;
        padding-bottom: 40px;
        background: url('/assets/img/signup-background-woman.webp');
        background-size: 47%;
        background-repeat: no-repeat;
        background-position: left bottom;
    }

    .howitworks-getadress-img {
        width: 59%;
    }

    .howitworks-shopfrom-img {
        width: 90%;
    }

    .howitworks-receive-img {
        width: 54%;
    }

    #instafeed img {
        display: block;
        width: 100%;
        max-height: 42vh;
        height: 42vh;
        object-fit: cover;
    }

    #instafeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .carousel-control-prev {
        left: -85px;
    }

    .carousel-control-next {
        right: -85px;
    }

    .mobile-center {
        text-align: justify
    }

    .header-underline-white-left {
        text-decoration: none;
        position: relative;
    }

    .header-underline-white-left::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        right: auto;
        width: 9%;
        background: white;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .header-underline-black-left {
        text-decoration: none;
        position: relative;
    }

    .header-underline-black-left::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        right: auto;
        width: 9%;
        background: black;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .header-underline-black-right {
        text-decoration: none;
        position: relative;
    }

    .header-underline-black-right::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        left: auto;
        width: 9%;
        background: black;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }


    .footer-logo {
        width: 85%;
        margin-top: 7px;
    }

    .footer-google-play {
        width: 85%;
    }

    .footer-app-store {
        width: 85%;
    }


    .footer-shippn-blog {
        width: 80%;
    }





}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .country-slide-padding {
        padding-left: 90px;
        padding-right: 90px;
    }

    .shippn-pagination-float {
        float: right;
    }

    .dashboard-card-content-border-radius {
        border-radius: 0px 0px 10px 0px;
    }

    .dashboard-card-header-border-radius {
        border-radius: 0px 10px 0px 0px;
    }

    .dashboard-nav-padding-right {
        padding-right: 0px !important;
    }

    // .dashboard-card-padding-left {
    //     padding-left: 0px !important;
    // }

    .dashboard-nav-height {
        height: 900px;
        border-radius: 10px 0px 0px 10px;
    }

    .dashboard-nav {
        padding: 0;
        list-style: none;
        margin-top: 10px;
        display: block;
        overflow: auto;
        width: 100%;
    }

    .dashboard-nav li {
        min-width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 10px 0px;
        cursor: pointer;
        color: white;
        border-radius: 10px;
        margin-top: 15px;
    }

    .signin-background-woman {
        padding-top: 85px;
        padding-bottom: 40px;
        background: url('/assets/img/signup-background-woman.webp');
        background-size: 38%;
        background-repeat: no-repeat;
        background-position: left bottom;
    }

    .shippn-become-a-host-background-color {
        background: linear-gradient(90deg, #f4f4f4 50%, #414141 50%);
    }

    .shippn-become-a-host-background-test {
        background: url(assets/img/background-flower.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100px;
        background-position-x: 25px;
    }


    .shippn-become-a-host-background-image {
        background: url(assets/img/become-a-host-arrow-background.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 910px;
        background-position-y: 447px;
    }

    // .become-a-host-first-area-height {
    //     height: 815px;
    // }

    .become-a-host-second-area-border {
        border-right: 1px solid #00000029;
        border-bottom: 0;
        height: 250px;
    }

    .become-a-host-area-left-padding {
        padding-right: 45px !important;
    }

    .about-team-img {
        width: 165px;
        border-radius: 50%;
        filter: brightness(0.8);
        transition: 1s;
    }

    #instafeed img {
        display: block;
        width: 100%;
        max-height: 59vh;
        height: 59vh;
        object-fit: cover;
    }

    #instafeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .btn-shippn-orange {
        color: black !important;
        border: 1px solid #ff6c36 !important;
        border-radius: 6px;
        padding: 6px 15px !important;
        transition: 0.5s !important;
        width: 100%;
        margin-bottom: 0px;

    }

    .btn-shippn-orange:hover {
        color: white !important;
        border: 1px solid #ff6c36 !important;
        background: #ff6c36 !important;
        border-radius: 6px;
        padding: 6px 15px !important;
        transition: 0.5s !important;
    }

    .nav-button {
        margin-left: 20px;
    }

    .carousel-control-prev {
        left: -85px;
    }

    .carousel-control-next {
        right: -85px;
    }




}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    .country-slide-padding {
        padding-left: 120px;
        padding-right: 120px;
    }

    .signin-background-woman {
        padding-top: 85px;
        padding-bottom: 40px;
        background: url('/assets/img/signup-background-woman.webp');
        background-size: 35%;
        background-repeat: no-repeat;
        background-position: left bottom;
    }

    .shippn-become-a-host-background-image {
        background: url(assets/img/become-a-host-arrow-background.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 1109px;
        background-position-y: 340px;
    }


    .become-a-host-second-area-border {
        border-right: 1px solid #00000029;
        border-bottom: 0;
        height: 200px;
    }

    .shippn-homepage-container {
        max-width: 1170px !important;
    }

    .faq-container {
        max-width: 800px !important;
    }

    #instafeed img {
        display: block;
        width: 100%;
        max-height: 33vh;
        height: 33vh;
        object-fit: cover;
    }

    #instafeed {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }

    .carousel-control-prev {
        left: -85px;
    }

    .carousel-control-next {
        right: -85px;
    }
}



// Shipper Network Additions Start

.shipper-network-form-box {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 60px;
}

.shipper-network-form-element {
    position: relative;
    flex: 50%;
}

.shipper-network-input {
    padding: 18px 16px !important;
}


.shippn-from-to-area-go-btn-green {
    background-color: #88d269 !important;
    padding: 7px 12px !important;
    border: #88d269 !important;
    color: white !important;
}

.shipper-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    line-height: 14px !important;
}

.shipper-network-submit {
    position: absolute !important;
    bottom: -60px;
    padding-left: 0 !important;
}

.shipper-network-submit-btn-wrapper {
    text-align: left;
    margin-top: 20px;
}

.small-device-form-pad-fix {
    padding-left: 0 !important;
}


@media (max-width: 991px) {

    .form-mobile-positioning {
        position: relative;
    }

    .shipper-network-form-box {
        display: flex;
        flex-direction: column-reverse;
        // padding-bottom: 0;
    }

    .shipper-network-form-element {
        position: inherit !important;
        margin-bottom: 10px;
    }

    .small-device-form-pad-fix {
        padding: 0 !important;
    }

    .shipper-network-form-box form {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .shipper-network-submit {
        position: absolute !important;
        bottom: 0;
        right: 15px;
    }

    .shipper-network-submit-btn-wrapper {
        text-align: right;
        padding: 0 !important;
    }

}

@media (max-width: 420px) {
    .shipper-btn-wrapper {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

// Extra small screen devices. eg: Galaxy Fold
@media (max-width: 360px) {
    .shipper-btn-wrapper {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .shipper-btn {
        font-size: 12px !important;
    }
}

// Shipper Network Additions End


// Homepage Blog Links Fix
.homepage-blog-title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 27px;
    margin-top: 35px;
}

.homepage-blog-links-wrapper {
    margin-bottom: 15px;
}

.homepage-blog-links {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
}

// Homepage Blog Links Fix End

// Dashboard Links Exclamation Mark
.dashboard-smallscreen-links,
.dashboard-bigscreen-links,
.dashboard-left-links {
    position: relative;
}

.dashboard-smallscreen-links-exclamation,
.dashboard-bigscreen-links-exclamation,
.dashboard-left-links-exclamation,
.dashboard-smallscreen-links-exclamation-non-active {
    position: absolute;
    display: flex !important;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    top: -12px;
    right: -22px;
    font-size: 14px;
    background-color: #fc7137;
    color: #f5f3f5;

}

.dashboard-left-links-exclamation {
    top: 0;
    left: calc(50% + 30px);
    transform: translateX(-50%);
}

// Dashboard Links Exclamation Mark End

// 20042022 Dashboard Update
.dashboard-smallscreen-links-exclamation-non-active {
    font-size: 10px;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    border-radius: 5px;
}

// Footer Aplication Area
.footer-application-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


// Static Landing Page Additions

.static-landing-page-heading-wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.static-landing-page-heading {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
}
.static-landing-page-subheading{
    color: #fff;
    font-size: 20px;
}

.padFix-padT-50 {
    padding-top: 50px;
}

.padFix-padB-50 {
    padding-bottom: 50px;
}

.landing-page-brands {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 150px));
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    list-style: none;
    padding: 0;
}

.landing-page-brands li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing-page-brands li a img {
    max-width: 100%;
}

.landing-page-brands li a h3 {
    color: #222222;
    font-size: 15px;
    font-weight: 400;
}


@media (max-width: 640px) {
    .static-landing-page-heading {
        font-size: 30px;
    }

    .padFix-padB-50 {
        padding-bottom: 10px;
    }

    .padFix-padT-50 {
        padding-top: 10px;
    }

    .landing-page-brands {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 140px));
        grid-row-gap: 30px;
        grid-column-gap: 30px;
        padding: 0;
        margin-left: 15px;
    }

}

// Footer Ui View Updates

.footer-partners {
    background: #414141;
    filter: grayscale(1);
    transform: translateZ(0);
}

.footer-partners-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.footer-partners-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
}

.footer-partners-image-container>img {
    max-width: 60%;
}

.partner-logo-fix>img {
    max-width: 75%;
}

// Dashboard Search & Sort Implement

.dashboard-control-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding-top: 25px;
    padding-left: 20px;
}

.dashboard-table-sort {
    position: relative;

    select {
        min-width: 250px;
        border: 1px solid #c3c7cb;
        border-radius: 4px;
        outline: 0;
        height: 32px;
    }
}

.dashboard-table-search-wrapper {
    display: flex;
    flex-direction: column;
}

.search-label {
    font-size: 12px;
}

.dashboard-table-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 250px;

    .fa-search {
        position: absolute;
        left: 8px;
    }


    input {
        width: 250px;
        padding-left: 30px;
        border: 1px solid #e6e6e6;
        box-shadow: 0 0 10px rgb(0 0 0 / 5%);
        background-color: #fff;
        border-radius: 4px;
        outline: 0;
        height: 32px;
    }
}

.dashboard-table-search.searchInputWithCamera {
    .fa-camera {
        position: absolute;
        left: 32px;
    }

    input {

        padding-left: 60px;
    }

}

.barcode-scanner-preview {
    width: 100%;
    height: 300px;
}

// @media screen (max-width: 505px){

// }


// Select Element Arrow


// select{
//     position: relative;
// }

// select:after{
//     display: block; 
//     content: "\f078";
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     width: 10px;
//     height: 10px;
//     background-color: #000;

// }


//Add Credit Card Modal
.add-credit-card-warning-modal {
    max-width: 700px !important;
    width: 700px !important;

    .modal-content {
        font-size: 14px;
        font-weight: 300;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .modal-header {
            background: #000;
            color: #fff;

            .close {
                color: #fff;
            }
        }

        .modal-body {
            padding: 15px;

            div,
            p {
                margin-top: 20px;
            }

        }

        .modal-footer {
            button {
                background-color: #fe6c36;
                border-color: #fe6c36;
            }
        }
    }
}

@media (max-width: 640px) {
    .add-credit-card-warning-modal {
        max-width: 95% !important;
        width: 95% !important;
        margin: auto !important;
    }
}

@media (min-width: 992px) {
    .desktop-valid-year-pad {
        padding-left: 0 !important;
    }
}

// Dashboard

/* Dashboard */

$dashboard-blue: #3d85b0;
$dashboard-orange: #ff6c36;

.dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--packs {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;

        &--pack {
            width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            display: grid;
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            margin: 5px 0;
            gap: 10px;
            grid-auto-rows: minmax(min-content, max-content);

            div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;

                p {
                    margin: 0;
                }
            }
        }
    }
}

// Dashboard Desktop End

// Dashboard Mobile 



@media (max-width: 2600px) {

    .no-result-found,
    .not-have-packs {
        display: flex;
        background: #fff;
        padding: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        p {
            margin: 0;
            font-size: 18px;
        }
    }

    .dashboard-control-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .dashboard-table-search,
    .dashboard-table-search>input,
    .dashboard-table-sort,
    .dashboard-table-sort>input {
        width: 100%;
        //padding-right: 30px;
    }

    .dashboard-table-search,
    .dashboard-table-sort {
        width: calc(50% - 15px);
    }

    .dashboard-arrow-mobile {
        right: 36px !important;
    }

    .dashboard--packs--pack {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        padding: 10px 20px;
        grid-auto-rows: minmax(min-content, max-content);
        grid-template-areas:
            "packageStatus packageStatus"
            "packageActions packageActions"
            "packageOrderNumber packageDimensions"
            "packageOrderDate packageFromSite"
            "packageShopperOrHostName packageInvoice"
            "packageCountry packageTracking"
            ". packageProductLink"
            "packageViewDetails packageContents";
        grid-template-columns: minmax(0, 1fr);

        div,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a {
            color: #707070;
            font-size: 14px;
        }

        &--actions {
            display: flex;
            flex-direction: row !important;
            justify-content: space-between !important;
            flex-wrap: wrap;
            grid-area: topArea;
            max-width: 100%;
            gap: 20px;

            &--btn,
            &--btn-blue,
            &--btn-white {
                width: 100%;
                max-width: 120px;
                min-width: 120px;
                font-size: 13px !important;
                font-weight: normal !important;
                padding: 5px 10px !important;
                background-color: $dashboard-orange !important;
                color: #fff !important;
                border-radius: 5px !important;
            }

            &--btn-blue {
                background-color: $dashboard-blue !important;
                color: #fff !important;
            }

            &--btn-white {
                background-color: transparent !important;
                border: 1px solid #a9a9a9 !important;
                color: #222 !important;
            }

            .dashboard-blue-btn {
                background-color: $dashboard-blue !important;
                color: #fff !important;
            }




        }

        &--contents {
            grid-area: bottomArea;
            align-items: flex-end !important;
            display: flex;
            flex-direction: column !important;
            justify-content: flex-end !important;

            * {
                color: #707070;
            }

            p {
                font-size: 12px;
            }

            div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                // p::after {
                //     content: "-";
                //     display: inline-block;
                //     margin-right: 4px;
                // }

                // p:last-of-type::after {
                //     display: none;
                // }
            }

            .item-link {
                flex-direction: column;
                align-self: flex-end !important;
                align-items: flex-end !important;
                justify-content: flex-end !important;
                font-size: 10px !important;

                a {
                    color: #707070;
                }
            }
        }

        &--status {
            grid-area: packageStatus;
            align-items: flex-start !important;
            font-weight: 500;
        }

        &--actions {
            grid-area: packageActions;
            //align-items: flex-end !important;
            margin-bottom: 5px;
        }

        &--order-number {
            grid-area: packageOrderNumber;
            align-items: flex-start !important;
            font-weight: 500;
        }

        &--order-date {
            grid-area: packageOrderDate;
            align-items: flex-start !important;
            font-weight: 500;
        }

        &--shopper-name,
        &--host-name {
            grid-area: packageShopperOrHostName;
            align-items: flex-start !important;

            .host-name {
                cursor: pointer;
            }
        }

        &--country {
            grid-area: packageCountry;
            align-items: flex-start !important;

            p {
                text-align: left;
            }
        }

        &--contents {
            grid-area: packageContents;
            align-items: flex-end !important;
        }

        &--view-details {
            grid-area: packageViewDetails;
            align-items: flex-start !important;
        }

        &--dimensions {
            grid-area: packageDimensions;
            align-items: flex-end !important;
        }

        &--from-site {
            grid-area: packageFromSite;
            align-items: flex-end !important;
        }

        &--invoice {
            grid-area: packageInvoice;
            align-items: flex-end !important;
        }

        &--tracking {
            grid-area: packageTracking;
            align-items: flex-end !important;

            p {
                text-align: right;
            }

            a {
                text-align: right;
                text-decoration: underline;
            }

        }

        &--product-link {
            grid-area: packageProductLink;
            align-items: flex-end !important;
        }
    }

    .host-expected-pack {
        grid-template-areas:
            "packageStatus packageStatus"
            "packageActions packageActions"
            "packageOrderNumber packageDimensions"
            "packageOrderDate packageFromSite"
            "packageShopperOrHostName packageInvoice"
            "packageCountry packageTracking"
            "packageViewDetails packageProductLink"
            ". packageContents";
    }

    .host-expected-pack {
        .dashboard--packs--pack--view-details {
            height: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .dashboard-packs-pack-field-title {
        font-size: 10px !important;
        color: $dashboard-orange !important;
        line-height: 10px !important;
        margin-bottom: 0px;
    }

    .dashboard--packs--pack--border-orange {
        border: 1px solid $dashboard-orange;
    }
}

.message-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    i {
        position: absolute;
        top: -8px;
        right: -8px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        padding: 4px 0;
    }
}

.dashboard--packs--pack--payment--preference {
    grid-area: paymentPreference;
    align-items: flex-end !important;
}

@media (max-width: 2600px) {
    .dashboard--packs--bfm {
        grid-template-areas:
            "packageStatus packageStatus"
            "packageActions packageActions"
            "packageOrderNumber bfmProccess"
            "packageOrderDate packageInvoice"
            "packageShopperOrHostName packageCountry"
            "packageContents paymentPreference";

        &--country {
            align-items: flex-end !important;
        }

        &--status {
            grid-area: bfmProccess;
            align-items: flex-end !important;
        }

        .dashboard--packs--pack--contents {
            align-items: flex-start !important;
        }

        .dashboard--packs--pack--contents .item-link {
            align-items: flex-start !important;
            align-self: flex-start !important;
        }
    }
}

// Dashboard Search & Sort Mobile View
@media (max-width: 768px) {
    .dashboard-control-container {
        display: flex;
        gap: 10px;
    }

    .dashboard-table-search,
    .dashboard-table-search>input,
    .dashboard-table-sort,
    .dashboard-table-sort>input {
        width: 100%;
        padding-right: 30px;
    }

    .dashboard-arrow-mobile {
        right: 36px !important;
    }

    .dashboard--packs--pack {
        width: 100%;
        max-width: 100%;
    }
}

// Dashboard Search & Sort Mobile View


// Dashboard Alignment Fixers
.justify-flex-end {
    justify-content: flex-end !important;
}

// Dashboard Alignment Fixers

:root {
    --shadow-white: white;
    --shadow-gray: #d1d3d7;
    --bg-gray: #fff;
    --text-purple: #6d5dfc;
    --shippn-flat-white: #fff;
    --shippn-flat-black: #000;
    --shippn-orange: #ff6c36;
    --shippn-grey: #707070;
    --shippn-blue: #3d85b0;
    --shippn-light-black: #222;
    --shippn-lighly-black: #444;
    --shippn-light-grey: #f5f5f7;
    --shippn-green: #3ba34a;
    --shippn-red: #f92a0a;
}

.dashboard--packagesstatus-menu {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    opacity: .8;

    div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h6 {
            margin: 0;
            font-size: 10px;
        }
    }
}

.dashboard-triple-menu,
.dashboard-quadro-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 5px;
    gap: 15px;
    //box-shadow: 1rem 0.3rem 0.6rem -0.6rem var(--shadow-gray);
    background-color: var(--bg-gray);
    border-bottom: 1px solid #ebebeb;

    button {
        padding: 10px 0;
        border-radius: 4px;
        color: #9296ad;
        font-size: 13px;
        border: 1px solid #e6e6e6;
        background: transparent;
        position: relative;

        p {
            margin: 0;

            display: inline-block;
        }
    }

    button.active {
        border-radius: 10px;
        color: $dashboard-orange;
        /* 	box-shadow: inset 0.2rem 0.2rem 0.5rem var(--shadow-gray),
		inset -0.2rem -0.2rem 0.5rem var(--shadow-white); */
        //box-shadow: inset 0.15em 0.15em 0.15em var(--shadow-white),
        //inset -0.15em -0.15em 0.15em var(--shadow-gray),
        //0.3rem 0.3rem 0.6rem var(--shadow-gray),
        //-0.3rem -0rem 0.6rem var(--shadow-white);
        border-radius: 5px;
        border: 1px solid $dashboard-orange;
        opacity: 1;
        background-color: #fdfcfc;
    }
}

.dashboard-quadro-menu {
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;

    button {
        font-size: 10px;
    }
}

.dashboard-one-menu-for-affiliate {
    grid-template-columns: 1fr;
}

@media (max-width: 414px) {
    .dashboard-triple-menu {
        padding: 10px 5px !important;

        button {
            font-size: 10px;
            max-width: 100px;
        }
    }

    .dashboard-smallscreen-links-exclamation-non-active {
        font-size: 9px;
        right: -8px;
        top: -8px;
    }

    .shopper-triple-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
            max-width: calc(50% - 20px);
            width: calc(50% - 20px);
            font-size: 12px;
        }
    }
}

.dashboard-main-menu {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    //box-shadow: 1rem 0.3rem 0.6rem -0.6rem var(--shadow-gray);
    background-color: var(--bg-gray);
    margin: 0;
    border-bottom: 1px solid #ebebeb;

    li {
        padding: 10px;
        display: block;
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        cursor: pointer;
        position: relative;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    li.active {
        position: relative;
        /* 	box-shadow: inset 0.2rem 0.2rem 0.5rem var(--shadow-gray),
		inset -0.2rem -0.2rem 0.5rem var(--shadow-white); */
        // box-shadow: inset 0.15em 0.15em 0.15em var(--shadow-white),
        //     inset -0.15em -0.15em 0.15em var(--shadow-gray),
        //     0.3rem 0.3rem 0.6rem var(--shadow-gray),
        //     -0.3rem -0rem 0.6rem var(--shadow-white);
        opacity: 1;
        //background-color: #fdfcfc;

    }

    .dashboard-smallscreen-links-exclamation-non-active {
        font-size: 9px;
        right: -8px;
        top: -8px;
    }


}

.dashboard-card-padding-left {

    //padding: 0!important;
    .row {
        margin: 0 !important;

        .col-md-12 {
            padding: 0 !important;
        }
    }
}

.actions-flex {
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.actions-btns {
    max-width: 120px;
    gap: 10px;
}

.dashboard--message-icon-exclamation {
    width: 19px;
    height: 18px;
    background-color: #fc7137;
    border-radius: 5px;
    position: relative;
    margin-left: 18px;
    bottom: -10px;
    align-items: center;
    justify-content: center !important;

    i {
        font-size: 13px;
        color: #fff;
    }
}

@media (max-width: 768px) {
    .actions-flex {
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .actions-btns {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 992px) {
    .shippn-homepage-container-dashboard {
        margin-top: 50px;
    }
}

@media (max-width: 1200px) {
    .shippn-homepage-container-dashboard {
        max-width: 1100px !important;
    }
}

@media (max-width: 992px) {
    .shippn-homepage-container-dashboard {
        overflow-x: hidden;
        padding: 0 !important;
    }
}

@media (max-width: 430px) {
    .host-dashboard-mailbox {
        gap: 10px;

        .actions-btns {
            gap: 5px;

            .dashboard--packs--pack--actions--btn {
                padding: 5px !important;
                max-width: calc(50% - 5px);
                min-width: calc(50% - 5px);
                font-size: 11px !important;
            }
        }
    }
}

// Dashboard


// Select -- Dropdown Arrow

.select--wrapper {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        z-index: 2;

        &:focus {
            background-color: transparent !important;
            background: transparent !important;
            outline: none;
        }
    }

    i {
        z-index: 1;
    }
}

// BFM Basket 
.shop--from--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around
}

@media (max-width: 768px) {
    .shop--from--wrapper {
        flex-direction: column;
        padding-bottom: 15px;
    }
}

// Campaign Better Brands Shimmer Walkaround
.campaign-better-brands-shimmer {
    width: 100%;
}

@media (min-width: 640px) and (max-width: 992px) {
    .campaign-better-brands-shimmer {
        width: 80%;
        margin: auto
    }
}


// BFM Product OG Tags Updates

.bfm-products-with-og-wrapper {
    display: flex;
    align-items: flex-start;
}

.bfm-basket-productForm-with-og-details {
    width: calc(100% - (170px + 2.5rem));
}


.bfm-product-details {

    display: flex;
    margin-left: 20px;
    width: calc(150px + 2.5rem);
    max-width: calc(150px + 2.5rem);
    justify-content: center;
    align-items: center;
    padding: 1.25rem;

    img {
        max-width: 150px;
        width: 150px;
        height: 150px;
        max-height: 150px;
        object-fit: cover;
    }
}

.show-only-mobile {
    display: none !important;
}

@media (max-width: 992px) {
    .show-only-desktop {
        display: none !important;
    }

    .show-only-mobile {
        display: flex !important;
    }

    .bfm-product-details {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
        margin: 0;

        img {
            max-width: 150px;
            width: 150px;
            height: 150px;
            max-height: 150px;
            object-fit: cover;
        }
    }

    .bfm-basket-productForm-with-og-details {
        width: 100%;
    }

    .bfm-products-with-og-wrapper {
        flex-direction: column;
    }
}

// BFM Items Card View

.products--detail--wrapper {
    margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    overflow: hidden;

    &--header {
        border-radius: 5px;
        background-color: #f9f9f9;
        display: grid;
        //grid-template-columns: repeat(6, 1fr);
        grid-template-columns: 1fr 100px 100px 100px 1fr 100px;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 100px) minmax(100px, 100px) minmax(100px, 100px) minmax(130px, 130px) minmax(90px, 90px);
        //grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));

        .products--detail--wrapper--header--title {

            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0;

            h6 {
                margin: 0;
                font-size: 12px;
                color: #000;
            }
        }

        .wrapper--header--title--product {
            justify-content: flex-start;
            padding-left: 15px;
        }

        .wrapper--header--title--unitPrice,
        .wrapper--header--title--quantity,
        .wrapper--header--title--totalPrice {
            --column-width-min: 100px;
        }

        .products--detail--wrapper--header--title:first-child {
            border: 0;
        }
    }
}

.products--detail--card {
    background-color: #f7f7f7;
    border-radius: 5px;
    display: grid;
    //grid-template-columns: repeat(6, 1fr);
    //grid-template-columns: 1fr 100px 100px 100px 1fr 100px;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 100px) minmax(100px, 100px) minmax(100px, 100px) minmax(130px, 130px) minmax(90px, 90px);

    &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 12px;
        color: #000;

    }

    &--item:first-child {
        border: 0;
    }

    .card--item--product {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;



        &--image {
            width: 75px;
            height: 70px;
            padding-left: 5px;
        }

        &--link {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            a {
                color: #000;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &--item.card--item--totalPrice,
    &--item.card--item--unitPrice {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        h6 {
            text-align: center;
            font-size: 12px;
            margin: 0;
        }
    }

    &--item.card--item--actions {

        .card--item--actions--buttons {
            display: flex;
            flex-direction: row;
            gap: 5px;

            div {
                background: #e3e3e3;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 26px;
                height: 26px;
                color: #444;
                border-radius: 3px;
                cursor: pointer;
            }
        }
    }
}

.products--detail--card--item.card--item--product--image {
    display: none;
}

.products--detail--card--item.card--item--totalPrice-mobile {
    display: none;
}

.products--detail--cart--wrapper--item {
    margin-top: 5px;
    display: block;
    //flex-direction: column;
    gap: 1px;
}

// BFM Checkout Card View Changes
.bfm--checkout--product--detail--wrapper>.products--detail--wrapper--header {
    grid-template-columns: 1fr 1fr 150px 150px 150px;
    grid-template-areas:
        "headproductLink headproductDescription headproductQuantity headproductUnitPrice headproductTotalPrice";

    .products--detail--wrapper--header--title {
        border: 0;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--description {
        grid-area: headproductDescription;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--quantity {
        grid-area: headproductQuantity;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--totalPrice {
        grid-area: headproductTotalPrice;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--unitPrice {
        grid-area: headproductUnitPrice;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--product {
        grid-area: headproductLink;
    }
}

.bfm--checkout--product--detail--wrapper>.products--detail--card--wrapper>.products--detail--cart--wrapper--item>.products--detail--card {
    grid-template-columns: 1fr 1fr 150px 150px 150px;
    grid-template-areas:
        "productLink productDescription productQuantity productUnitPrice productTotalPrice";

    .products--detail--card--item {
        border: 0;
    }

    .products--detail--card--item.card--item--description {
        grid-area: productDescription;
        justify-content: flex-start;
    }

    .products--detail--card--item.card--item--quantity {
        grid-area: productQuantity;
    }

    .products--detail--card--item.card--item--totalPrice {
        grid-area: productTotalPrice;
    }

    .products--detail--card--item.card--item--unitPrice {
        grid-area: productUnitPrice;
    }

    .products--detail--card--item.card--item--product {
        grid-area: productLink;
        justify-content: flex-start;
    }
}

// BFM Checkout Card View Changes

// BFM Order Success

.bfm--order--success--detail>.products--detail--wrapper>.products--detail--wrapper--header {
    grid-template-columns: 250px 1fr 100px 100px 100px;
    grid-template-areas:
        "headproductLink headproductDescription headproductQuantity headproductUnitPrice headproductTotalPrice";

    .products--detail--wrapper--header--title {
        border: 0;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--description {
        grid-area: headproductDescription;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--quantity {
        grid-area: headproductQuantity;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--totalPrice {
        grid-area: headproductTotalPrice;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--unitPrice {
        grid-area: headproductUnitPrice;
    }

    .products--detail--wrapper--header--title.wrapper--header--title--product {
        grid-area: headproductLink;
    }

}

.bfm--order--success--detail>.products--detail--wrapper>.products--detail--card--wrapper>.products--detail--cart--wrapper--item>.products--detail--card {
    grid-template-columns: 250px 1fr 100px 100px 100px;
    grid-template-areas:
        "productLink productDescription productQuantity productUnitPrice productTotalPrice";

    .products--detail--card--item {
        border: 0;
    }

    .products--detail--card--item.card--item--description {
        grid-area: productDescription;
        justify-content: flex-start;
    }

    .products--detail--card--item.card--item--quantity {
        grid-area: productQuantity;
    }

    .products--detail--card--item.card--item--totalPrice {
        grid-area: productTotalPrice;
    }

    .products--detail--card--item.card--item--unitPrice {
        grid-area: productUnitPrice;
    }

    .products--detail--card--item.card--item--product {
        grid-area: productLink;
        justify-content: flex-start;
    }
}

// BFM Order Success 
.products--detail--bfm-number {
    color: $dashboard-orange;
}

@mixin before-label($direction, $text-align) {
    position: absolute;
    top: 10px;
    $direction: 15px;
    width: 100%;
    font-weight: bold;
    color: $dashboard-orange;
    text-align: $text-align;
    font-size: 10px;
}

@media (max-width: 1200px) {
    .products--detail--wrapper--header {
        display: none;
    }

    .products--detail--bfm-number {
        background-color: #f9f9f9;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 5px;
        color: $dashboard-orange;
    }

    .products--detail--card {
        grid-template-columns: 1fr minmax(100px, 1fr);
        gap: 5px;
        padding-bottom: 5px;
        grid-template-areas:
            "productLink productActions"
            "productDescription productImage"
            "productQuantity productImage"
            "productUnitPrice productTotalPrice";

        &--item {
            align-items: flex-start;
            justify-content: flex-start;
            border: 0;
        }
    }

    .bfm--order--success--detail>.products--detail--wrapper>.products--detail--card--wrapper>.products--detail--cart--wrapper--item>.products--detail--card,
    .bfm--checkout--product--detail--wrapper>.products--detail--card--wrapper>.products--detail--cart--wrapper--item>.products--detail--card {
        grid-template-columns: 1fr minmax(100px, 1fr);
        gap: 5px;
        grid-template-areas:
            "productLink ."
            "productDescription productImage"
            "productQuantity productImage"
            "productUnitPrice productTotalPrice";

        .products--detail--card--item {
            border: 0;
        }

        .products--detail--card--item.card--item--description {
            grid-area: productDescription;
            justify-content: flex-start;
        }

        .products--detail--card--item.card--item--quantity {
            grid-area: productQuantity;
        }

        .products--detail--card--item.card--item--totalPrice {
            grid-area: productTotalPrice;
        }

        .products--detail--card--item.card--item--unitPrice {
            grid-area: productUnitPrice;
        }

        .products--detail--card--item.card--item--product {
            grid-area: productLink;
            justify-content: flex-start;
        }
    }

    .products--detail--card--item {
        padding: 0;
        padding-top: 10px;
    }

    .products--detail--card--item.card--item--product--image {
        display: flex;
        grid-area: productImage;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding-right: 15px;

        img {
            width: 100%;
            height: 100%;
            max-width: 70px;
            max-height: 70px;
        }
    }

    .products--detail--card--item.card--item--actions {
        grid-area: productActions;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 25px;
        padding-right: 15px;
        position: relative;

        &:before {
            content: "Actions";
            @include before-label(right, right);
        }

    }

    .products--detail--card--item.card--item--description {
        grid-area: productDescription;
        justify-content: flex-start;
        padding-top: 25px;
        padding-left: 15px;
        position: relative;

        &:before {
            content: "Description";
            @include before-label(left, left);
        }
    }

    .products--detail--card--item.card--item--quantity {
        grid-area: productQuantity;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 25px;
        padding-left: 15px;
        position: relative;

        &:before {
            content: "Quantity";
            @include before-label(left, left);
        }

        // p{
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     font-size: 12px;
        //     padding: 5px 10px;
        //     border: 1px solid #e4e5e5;
        //     margin: 0;
        // }

        .bfmQuantityButtons {
            color: #444;
            background: #e3e3e3;
            border-radius: 3px;
        }
    }

    .products--detail--card--item.card--item--totalPrice {

        grid-area: productTotalPrice;
        justify-content: flex-start;
        align-items: flex-end;
        padding-top: 25px;
        padding-right: 15px;
        position: relative;
        min-height: 48px;

        &:before {
            content: "Total Price";
            @include before-label(right, right);
        }


    }

    .products--detail--card--item.card--item--unitPrice {
        grid-area: productUnitPrice;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 25px;
        padding-left: 15px;
        position: relative;
        min-height: 48px;

        &:before {
            content: "Unit Price";
            @include before-label(left, left);
        }
    }

    .products--detail--card--item.card--item--product {
        grid-area: productLink;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 25px;
        padding-left: 15px;

        &:before {
            content: "Product";
            @include before-label(left, left);
        }

        .card--item--product--image {
            display: none;
        }
    }

    .products--detail--card--item.card--item--totalPrice-mobile {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        background: #e3e3e3;
        border-radius: 5px;
        position: relative;
        padding-top: 25px;
        padding-right: 15px;

        &:before {
            content: "Total Price";
            @include before-label(right, right);
        }

        h6 {
            font-size: 12px;
        }
    }

}

.yt-wrapper_align_right {
    display: none !important;
}

#google_translate_element {
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fff;
    width: 240px;
    border-radius: 5px;
}

.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-banner-frame {
    display: none !important;
}

body {
    top: 0 !important;
}

// Google Translate Original Text Fix
.goog-tooltip {
    display: none !important;
}

.goog-tooltip:hover {
    display: none !important;
}

.goog-text-highlight {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    display: none;
}

// Translate Button Fix

.shippn-from-to-area-go-btn {
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
}

// SHPN-5343 New Verification Charge API

.card-verify-input {
    padding-left: 15px;
    letter-spacing: 42px;
    border: 0 !important;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 200px;
    outline: none;
    text-align: left;
}

.form-control:focus {
    box-shadow: none;
}

.otpInputC {
    font-size: 24px;
}

/* === Settings Backgrounds */

.bg-light-grey {
    background-color: var(--shippn-light-grey) !important;
}

.shippn-box-shadow {
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%);
}

.shippn-box-shadow-offset-0 {
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.input-group-text {
    border-color: #e6e6e6;
}

.bg-white {
    background-color: var(--shippn-flat-white) !important
}

.b-rad-5 {
    border-radius: 5px;
}

.input-with-currency-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}

.input-with-currency-wrapper {

    .currency-wrapper {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-wrapper {
        .shippn-input {
            border: 0;
            box-shadow: none !important;
        }
    }
}

.phone-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%) !important;
    /* border-color: #000; */
    gap: 5px;
}

.phone-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.phone-number-before {
    white-space: nowrap;
}

.phone-input>input {
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 16px !important;
    padding-left: 10px !important;
}

.country_flag {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fdfdff;
    gap: 5px;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}



.phone-country-code-selection,
.currencySelection {
    background: #fff;
    margin-top: 10px;
}

.phone-input-country-search {
    padding: 10px 10px 2px;
}

.phone-input-country-search>input {
    min-height: 48px;
    outline: 0 !important;
}

.phone-input-country-search>input.form-control:focus {
    min-height: 48px;
    outline: 0 !important;
    box-shadow: none;
}


.phone-input-country-list,
.currencySelection-list {
    max-height: 220px;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.phone-input-country-list>ul {
    list-style-type: none;
    padding: 0;
    background: #fff;
}

.phone-input-country-list>ul>li {
    padding: 10px 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    transition: all ease-in-out .2s;
}

.phone-input-country-list>ul>li:hover {
    background: #f4f4f8;
    cursor: pointer;
    border-radius: 5px;
}

.currency-code-selection {
    margin: 0;
    top: 0;
    left: 0 !important;
    width: 100% !important;
    border: 1px solid #ced4da !important;
    max-height: 360px !important;
}

.currency-searched-list-wrapper {
    padding: 5px 10px;
    max-height: 200px;
}

.currency-searched-list-wrapper>ul>li {
    padding: 5px;
}

/*=== === Animations === ===*/

.rotate-180deg {
    animation: rotate180deg .25s cubic-bezier(0.075, 0.82, 0.165, 1)forwards;
}

.rotate-0deg {
    animation: rotate0deg .25s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes rotate180deg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }

}

@keyframes rotate0deg {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }

}

.phone-country-code-selection {
    position: absolute;
    z-index: 999;
    left: 15px;
    width: calc(100% - 30px);
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%);
    border-radius: 5px;
    overflow: hidden;
}

.stage-in {
    opacity: 0;
    animation: stageIn .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.stage-out {
    opacity: 1;
    animation: stageOut .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes stageIn {
    0% {
        top: 100px;
        opacity: 0;
    }

    100% {
        top: 50px;
        opacity: 1;
    }
}

@keyframes stageOut {
    0% {
        top: 50px;
        opacity: 100%;
    }

    100% {
        top: 100px;
        opacity: 0;
    }
}


.form-control,
.selected-list .c-btn,
.btn-outline-secondary {
    border-color: #e6e6e6 !important;
}

.selected-list .c-btn {
    box-shadow: 5px 5px 10px rgb(0 0 0 / 5%);
}

.shippn-border-red {
    border: 1px solid red !important;
}

.customTooltipForOrderConfirmation.show {
    opacity: 1 !important;
}

.customTooltipForOrderConfirmation.fade.show.bs-tooltip-top {
    width: 360px !important;
}

.customTooltipForOrderConfirmation,
.customTooltipForOrderConfirmation>.tooltip-inner {
    // width: 100%!important;
    max-width: 360px !important;
    padding: 10px !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.skiptranslate {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
    display: none !important;
}


.top-rated-background {
    position: relative;
}

.top-rated-desktop-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top-rated-mobile-image {
    display: none;
}

@media (max-width: 576px) {
    .top-rated-desktop-image {
        display: none;
    }

    .top-rated-mobile-image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


/* SHPN-5731 New Chat Icon*/

.message-icon-wrapper {
    display: flex;
    width: fit-content;
    cursor: pointer;
    flex-direction: row !important;
    gap: 10px
}

.unread-message-count {
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #f36c37;
    position: absolute;
    right: 0;
    top: 0;
    right: -10px;
    top: -10px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #ebba18;
}



:root {
    --booster-black: #08090A;
    --booster-white-smoke: #F4F4F4;
    --booster-silver-lake: #778DA9;
    --booster-saffron: #EBBA18;
    --booster-crayola: #FF6C36;
    --booster-text-white: #fdfdfd;
    --booster-text-black: #020202;
    --booster-aerospace: #FF4B0A;
    --hello-yale-blue: #0d316c;
    --hello-naples-yellow: #F2CD5D;
    --hello-earth-yellow: #DEA54B;
}

.cdk-overlay-container {
    z-index: 9999;
}

.shippn-time-picker-arange-pickup button:not([disabled]) .mdc-button__label {
    font-weight: bold;
    color: #404040;
}

.shippn-mat-stroked-btn .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.shippn-mat-stroked-btn .mdc-button__ripple,
.shippn-mat-stroked-btn:hover .mdc-button__ripple {
    background-color: #fff !important;
}

.shippn-mat-stroked-btn .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.shippn-mat-stroked-btn .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: #fff;
}

.shippn-mat-btn .mdc-button__label {
    color: #fff;
}

.shippn-mat-btn-black {
    border: 1px solid #ddd !important;
}

.shippn-mat-btn-black .mdc-button__label {
    color: #4f4f4f;
}

.shippn-mat-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #fff !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: var(--shippn-orange);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--shippn-orange);
}

.mat-mdc-tab-body-wrapper {
    flex-grow: 1;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
    height: 100%
}

.mat-mdc-tab-group {
    height: 100%
}

@media screen and (max-width: 576px) {
    .shippn-host-store-table {
        font-size: 12px;
    }

    .shippn-host-store-table .btn-danger {
        font-size: 12px;
    }

    .shippn-host-store-table .mdc-data-table__cell {
        padding: 4px 8px
    }

    :root {
        --mdc-checkbox-state-layer-size: 32px;
    }
}



#shippn-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ebebeb;
}

#shippn-scroll::-webkit-scrollbar {
    width: 6px;

}

#shippn-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff993e;
}



app-sticky-action-bar {
    position: fixed;
    bottom: 30px;
    right: 5px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

@media screen and (max-width: 576px) {
    app-sticky-action-bar {
        width: auto;
        max-width: 210px;
        min-width: 140px;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        display: none
    }
}



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

#getNewAdressModalTrigger {
    display: none !important;
}

.mat-mdc-checkbox label {
    margin: 0;
    padding: 0;
}

button:focus,
button:active,
button:hover {
    outline: 0 !important;
    box-shadow: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.mat-mdc-dialog-container .mdc-dialog__title {
    font-family: 'Roboto';
}


@media (max-width: 992px) {

    .mdc-dialog .mdc-dialog__content,
    .mat-horizontal-content-container {
        padding: 0 15px 30px !important;
    }
}

.mat-horizontal-content-container {
    padding: 0 15px !important;
}

.mat-typography {
    font-family: 'Roboto', sans-serif;
}



.shippn-host-consolidation-stepper .mat-horizontal-stepper-header-container {
    justify-content: center;
}

.shippn-host-consolidation-stepper .mat-stepper-horizontal-line {
    display: none;
}

.shippn-host-consolidation-stepper .mat-horizontal-stepper-header-container {
    gap: 10px;
}

.shippn-host-consolidation-stepper .mat-step-header {
    width: 20px;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 10px !important;
}

.shippn-host-consolidation-stepper .mat-step-header .mat-step-header-ripple {
    width: 12px;
    height: 12px;
    background-color: #757575;
    border-radius: 6px;
    margin: 4px;
}

.shippn-host-consolidation-stepper .mat-step-header:hover .mat-step-header-ripple {
    background-color: #555;
}

.shippn-host-consolidation-stepper .mat-step-icon-state-number {
    display: none;
}

.shippn-host-consolidation-stepper .mat-step-icon {
    display: none;
}

.parent-order-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
    background-color: var(--shippn-orange) !important;
}

.shippn-host-consolidation-dialog-content {
    padding: 0 40px 5px !important;
}

.mat-mdc-dialog-surface {
    overflow: initial !important;
}

@media (max-width: 576px) {
    .cdk-overlay-pane {
        max-width: 90vw !important;
    }
}

.shippn-host-consolidation-stepper .mat-horizontal-stepper-header-container {
    width: fit-content;
    margin: 0 auto;
}

.shippn-host-consolidation-stepper .mat-horizontal-stepper-wrapper {
    max-width: 800px;
    margin: auto;
}

#getNewAdressModalTrigger {
    display: none !important;
}

.shippn-host-consolidation-stepper .mat-mdc-checkbox label {
    margin: 0;
    padding: 0;
}

button:focus,
button:active,
button:hover {
    outline: 0 !important;
    box-shadow: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.mat-mdc-dialog-container .mdc-dialog__title {
    font-family: 'Roboto';
}


@media (max-width: 992px) {

    .mdc-dialog .mdc-dialog__content,
    .mat-horizontal-content-container {
        padding: 0 15px 30px !important;
    }
}

.mat-horizontal-content-container {
    padding: 0 15px !important;
}

.mat-typography {
    font-family: 'Roboto', sans-serif;
}


.shippn-host-consolidation-stepper .mat-horizontal-stepper-header-container {
    justify-content: center;
}

.shippn-host-consolidation-stepper .mat-stepper-horizontal-line {
    display: none;
}

.shippn-host-consolidation-stepper .mat-horizontal-stepper-header-container {
    gap: 10px;
}

.shippn-host-consolidation-stepper .mat-step-header {
    width: 20px;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 10px !important;
}

.shippn-host-consolidation-stepper .mat-step-header .mat-step-header-ripple {
    width: 12px;
    height: 12px;
    background-color: #757575;
    border-radius: 6px;
    margin: 4px;
}

.shippn-host-consolidation-stepper .mat-step-header:hover .mat-step-header-ripple {
    background-color: #555;
}

.shippn-host-consolidation-stepper .mat-step-icon-state-number {
    display: none;
}

.shippn-host-consolidation-stepper .mat-step-icon {
    display: none;
}

.parent-order-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
    background-color: var(--shippn-orange) !important;
}

.shippn-host-consolidation-dialog-content {
    padding: 0 40px 5px !important;
}

.mat-mdc-dialog-surface {
    overflow: initial !important;
}

@media (max-width: 576px) {
    .cdk-overlay-pane {
        max-width: 90vw !important;
    }
}

.shippn-become-an-affiliate-stepper .mat-step-icon {

    display: flex;
}

.shippn-become-an-affiliate-stepper .mat-step-header {
    width: 72px;
    height: 100px !important;
    display: block;
    text-align: center;
    background: transparent;
}

.shippn-become-an-affiliate-stepper .mat-step-header:hover,
.shippn-become-an-affiliate-stepper .mat-step-header:focus,
.shippn-become-an-affiliate-stepper .mat-step-header:focus-visible {
    background: transparent;
}

.shippn-become-an-affiliate-stepper .mat-step-header .mat-step-header-ripple {
    width: 60px;
    height: 60px;
    margin: 6px;
    background-color: transparent;
    border-radius: 30px;

}

.shippn-become-an-affiliate-stepper .mat-step-header .mat-step-header-ripple:hover,
.shippn-become-an-affiliate-stepper .mat-step-header .mat-step-header-ripple:focus,
.shippn-become-an-affiliate-stepper .mat-step-header .mat-step-header-ripple:focus-visible {
    background: transparent;
}

.shippn-become-an-affiliate-stepper .mat-horizontal-stepper-header .mat-step-icon {
    width: 48px !important;
    height: 48px !important;
    margin: 12px auto !important;
    border: 4px solid #fff !important;
}

.shippn-become-an-affiliate-stepper .mat-step-icon-content {
    color: #fff;
}

.shippn-become-an-affiliate-stepper .mat-step-icon .mat-icon {
    font-size: 30px;
    height: 30px;
    width: 30px;
}

.shippn-become-an-affiliate-stepper .mat-horizontal-content-container {
    margin-top: 40px;
}

.shippn-become-an-affiliate-stepper .mat-step-header .mat-step-label.mat-step-label-active,
.shippn-become-an-affiliate-stepper-step-1-done .mat-step-text-label {
    color: var(--shippn-orange);
}

.shippn-become-an-affiliate-stepper .mat-horizontal-stepper-header-container {
    gap: 0;
}

.shippn-become-an-affiliate-stepper .mat-stepper-horizontal-line {
    display: block;
    border-top-width: 2px;
    min-width: 140px;
    margin-bottom: 25px;
}

.shippn-become-an-affiliate-stepper-step-1-done .mat-stepper-horizontal-line {
    border-color: var(--shippn-orange);
}

.shippn-become-an-affiliate-stepper .mat-step-text-label {
    font-weight: bold;
}



// Alyle UI
.LySlider_46color_58accent-ap .track-j,
.LySlider_46color_58accent-ap .thumb-n,
.LySlider_46color_58accent-ap .thumbLabel-o,
.LySlider_46color_58accent-ap .y-bg-k,
.LySlider_46color_58accent-ap .tick-v {
    background-color: var(--shippn-orange) !important;
}





// CLS Walkaround TRY 1
app-become-an-affiliate {
    width: 100%;
    min-height: 100vh;
}

ly-slider div {
    background-color: var(--shippn-orange) !important;
}

.ap:not(.ab) .m::before,
.ap .j,
.ap .n,
.ap .o,
.ap .k,
.ap .v {
    background-color: var(--shippn-orange) !important;
}

:host {
    --swiper-theme-color: #ff6c36 !important;
}

.back-to-dashboard-btn,
.back-to-store-btn {
    border: 1px solid #ddd !important;
    background-color: transparent !important;
    color: #4f4f4f !important;
    font-size: 12px !important;
}


.shippn-btn-orange {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    color: #fff;
    background-color: var(--shippn-orange);
    min-width: 120px;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
}



.overflow-hidden-menu-opened,
.overflow-hidden-addProduct-opened {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
    width: 100%;
    height: 100vh;
    position: fixed !important;
    top: 0 !important;
    transition: all ease-in .25;
}




/**/

.shippn-b2b-create-shipment-stepper .mat-horizontal-stepper-header-container {
    max-width: 100%;
    overflow-x: auto;
}


.shippn-b2b-create-shipment-stepper .mat-horizontal-stepper-header {
    height: 60px;
}

.shippn-b2b-create-shipment-stepper .mat-stepper-horizontal-line {
    margin: 0 5px;
}


.shippn-b2b-create-shipment-stepper .mat-horizontal-stepper-header .mat-step-icon {
    background-color: #eaeaea8a;
}

.shippn-b2b-create-shipment-stepper .mat-horizontal-stepper-header .mat-step-icon-selected {
    background-color: var(--shippn-orange);
}

.shippn-sales-funnel-mat-stepper .mat-horizontal-stepper-header .mat-step-icon {
    background-color: transparent;
    border: 2px solid #6e6a6a;
}
.shippn-sales-funnel-mat-stepper .mat-horizontal-stepper-header .mat-step-icon-selected{
    background-color: var(--shippn-orange);
    border: 0;
}
.shippn-sales-funnel-mat-stepper .mat-step.shippn-sales-step-completed .mat-step-icon{
    background-color: var(--shippn-orange);
    border-color: var(--shippn-orange);
}

.shippn-sales-funnel-mat-stepper .mat-horizontal-stepper-header .mat-step-icon .mat-step-icon-content{
    display: none;
}

@media (max-width: 768px) {
    .shippn-sales-funnel-mat-stepper .mat-horizontal-stepper-header .mat-step-label{
        display: none;
    }
}



.shippn-mat-btn-alt {
    border: 1px solid var(--shippn-orange) !important;
    background-color: transparent !important;
}

.shippn-mat-btn-alt .mdc-button__label {
    color: var(--shippn-orange) !important;
}

.shippn-form-label {
    color: var(--shippn-orange);
    margin: 5px 0;
    font-weight: 500;
    font-size: 14px;
}

.measurment-radio-button>.mdc-form-field>label {
    margin-bottom: 0 !important;
    font-size: 14px;
    padding-left: 0;
    padding-right: 5px;
    cursor: pointer;
}

.b2b-create-shipment-datepicker .mat-mdc-text-field-wrapper {
    max-height: 52px;
    max-width: calc(100% - 30px);
    width: calc(100% - 30px);
}

.b2b-create-shipment-datepicker .mdc-text-field {
    border-radius: 5px;
}

.b2b-create-shipment-datepicker .mat-mdc-form-field-flex {
    max-height: 38px;
}

.b2b-create-shipment-datepicker .mat-mdc-form-field-infix {
    padding-top: 7px !important;
}

.b2b-create-shipment-datepicker .mdc-line-ripple {
    display: none;
}

.pac-container {
    z-index: 9999 !important;
}

.hide-stepper-header .mat-horizontal-stepper-header,
.hide-stepper-header .mat-stepper-horizontal-line {
    display: none;
}

.mat-sort-header-content {
    text-align: left !important;
}

.b2b-shipment-list-table thead {
    position: sticky;
    top: 0;
}



.content-hts-selection-b2b {
    background: #fff;
    /* margin-top: 10px; */
}

.content-hts-search-b2b {
    padding: 0;
}

.content-hts-search-b2b>input {
    min-height: 42px;
    outline: 0 !important;
    font-size: 13px;
}

.content-hts-search-b2b>input.form-control:focus {
    /* min-height: 48px; */
    outline: 0 !important;
    box-shadow: none;
}

.content-hts-list-b2b {
    max-height: 175px;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.content-hts-list-b2b>ul {
    list-style-type: none;
    padding: 0;
    background: #fff;
}

.content-hts-list-b2b>ul>li {
    padding: 10px 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    transition: all ease-in-out .2s;
    font-size: 13px;
}

.content-hts-list-b2b>ul>li:hover {
    background: #f4f4f8;
    cursor: pointer;
    border-radius: 5px;
}

/*=== === Animations === ===*/

.rotate-180deg {
    animation: rotate180deg .25s cubic-bezier(0.075, 0.82, 0.165, 1)forwards;
}

.rotate-0deg {
    animation: rotate0deg .25s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes rotate180deg {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }

}

@keyframes rotate0deg {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }

}

.content-hts-selection-b2b {
    position: absolute;
    z-index: 999;
    left: 0;
    width: 100%;
    box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 6%);
    border-radius: 5px;
    overflow: hidden;
    min-width: 200px;
}

.stage-in-b2b {
    opacity: 0;
    animation: stageInB2B .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.stage-out-b2b {
    opacity: 1;
    animation: stageOutB2B .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes stageInB2B {
    0% {
        top: 100px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes stageOutB2B {
    0% {
        top: 50px;
        opacity: 100%;
    }

    100% {
        top: 100px;
        opacity: 0;
    }
}

.shipment-details-package-information-radio-group {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
}

.shipment-details-package-information-radio-group .mdc-form-field {
    border: 1px solid #eee;
    border-radius: 5px;
    width: 100%;
}

.b2b-main-radio-group .mdc-form-field{
    box-shadow: none!important
}

.shipment-details-package-information-radio-group label {
    margin: 0;
    padding-left: 0 !important;
    padding-right: 10px !important;
    font-size: 14px;
    font-weight: 500;
    color: #222;
}

.shipment-detail-add-link-btn {
    min-height: 42px;
}

.shippn-shipments-detail-blue {
    background-color: #216493;
}


@media (max-width: 768px) {
    .bfm-int-input {
        padding-left: 6px;
        padding-right: 6px;
        min-height: 35px;
        font-size: 12px !important;
    }
}

.mat-bottom-sheet-container {
    min-width: calc(100vw - 60px) !important;
    max-width: calc(100vw - 60px) !important;
    border-radius: 15px;
}

.custom-bottom-sheet-width-margin {
    margin-bottom: 120px !important;
}

.shippn-input-bfm-basket-currency {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    border-radius: 5px;
    padding: 12px 12px !important;
    font-size: 12px !important;
    background-color: transparent;
    height: 42px;
}

.shippn-input-bfm-basket-country-select {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    border-radius: 5px;
    padding: 12px 15px !important;
    background-color: white;
    height: 42px;
}

.shippn-input-bfm-basket-text-input {
    min-height: 42px;
}

.lp-countries-accordion-item-content-right p strong{
    font-weight: 600;
    font-family: 'Montserrat'!important;
}

@media (max-width:576px) {

    input:focus,
    select:focus,
    textarea:focus {
        font-size: 16px !important;
        padding: 3px 15px !important;
        min-height: 42px;
    }
    .otpInputC:focus{
        padding: 3px 3px!important;
    }
    .currencyEntryPoint-input,
    .currencyEntryPoint-input:focus {
        font-size: 14px!important;
        height: 35px!important;
        max-height: 35px;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
        border-radius: 5px;
        background-color: transparent;
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
        padding-top: 12px!important;
        padding-bottom: 12px!important;
        padding-right: 12px!important;
        padding-left: 5px!important;
    }
}

.shippnpilot-widget-wrapper, .shippnpilot-widget-wrapper-mobile{
   
    padding: 30px 0;
    background-color: #fff;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #0000000d;
    display: block;
  }
  
  .shippnpilot-widget-wrapper-mobile{
    display: none;
    padding: 5px 0 0;
  }
  
  @media (max-width: 768px) {
    .shippnpilot-widget-wrapper{
      display: none;
    }
    .shippnpilot-widget-wrapper-mobile{
      display: block;
    }
  }
  
  @media (min-width: 768px){
    .shippnpilot-widget-wrapper-mobile{
      display: none;
    }
    .shippnpilot-widget-wrapper{
      display: block;
    }
  }

  @media (min-width:420px){
    .tp-widget-trustscore{ 
        font-size: 14px!important;
    }
  }

  .mt-top40{ 
    // margin-top: 40px;
    margin: 0;
    
  }



.stage-in2 {
    opacity: 0;
    animation: stageIn2 .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.stage-out2 {
    opacity: 1;
    animation: stageOut2 .35s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes stageIn2 {
    0% {
        top: 100px;
        opacity: 0;
    }

    100% {
        top: 50px;
        opacity: 1;
    }
}

@keyframes stageOut2 {
    0% {
        top: 50px;
        opacity: 100%;
    }

    100% {
        top: 100px;
        opacity: 0;
    }
}
@media (max-width:768px) {

    .bfm-int-input,
    .bfm-int-input:focus{
        padding-left: 6px!important;
        padding-right: 6px!important;
        min-height: 35px;
    }
}

.yt-player-dialog-content{ 
    background-color: #000;
    padding: 0!important;
    padding-bottom: 15px!important;
}

.b2b-shipmentlists-date-range-picker > .mat-mdc-form-field{
    height: 38px;
    width: 100%;
}
.b2b-shipmentlists-date-range-picker .mat-mdc-form-field-flex,
.b2b-shipmentlists-date-range-picker>.mat-mdc-form-field-type-mat-date-range-input>.mat-mdc-text-field-wrapper{
    height: 38px;
    max-height: 38px;
    min-height: 38px!important;
}

.b2b-shipmentlists-date-range-picker>.mat-mdc-form-field-type-mat-date-range-input>.mat-mdc-text-field-wrapper>.mat-mdc-form-field-flex >.mat-mdc-form-field-infix{
    padding-top: 6px!important;
    padding-bottom: 6px!important;
    font-size: 13px;
}
.mat-date-range-input-container > .mat-date-range-input-wrapper{ 
    min-width: 65px;
}

@media (min-width:1280px){
    .b2b-dashboards-container{
      max-width: 100vw!important;
    }
  }

  .margin-top-0{
    margin-top: 0;
  }

  .justify-space-between{
    justify-content: space-between;
  }

  .table-responsive {
    overflow-x: auto;
  }
  .b2b-table-responsive table {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    .mat-column-pickup_location{
        flex:none;
        max-width: 100vw;
        min-width: calc(100vw - 30px);
        line-height: 32px;
        text-overflow: initial!important;
        white-space: normal;
        word-wrap: break-word;
    
    }

    .col-date > .form-group > .mat-mdc-form-field{
        width: 100%;
    }
    .b2b-pickups-dashboard-card-header-border-radius{
        border-radius: 0;
        border:0;
    }
  }